.cart-list {
  @extend .default, .rounded-std;
  font-size: 16px;

  margin-top: 10px;
  margin-bottom: 5px;
  margin-right: 5px;
  margin-left: 5px;

  padding: 10px;
  padding-bottom: 15px;

  .result {
    margin-top: 10px ;
    .totalPrice {
      font-weight: bold;
    }
  }

  .empty {
    text-align: right;
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 10px;
  }

  .indivdualSurchage {
    input {
      width: 45px;
      display: inline;
      height: 28px;
      background-color: $balanced;
      border-top: 1px solid white;
      border-bottom: 1px solid white;
      margin-left: 5px;
      text-align: center;
      color: white;
      font-weight: bold;
      font-size: 16px;
      padding-bottom: 6px;
      position: relative;
    }
  }

  .cart-item {

    border-bottom: 1px white solid;
    padding: 16px 0;

    .content {
      & > div {
        margin-bottom: 4px;
      }
    }

    .price-select {
      @extend .item, .item-input, .item-select;
      height: 30px;
      width: 115px;
      padding-left: 0px;
      @extend .default;
      border-left: none;
      border-right: none;
      margin: 12px 0px !important;

      &:after {
        color: white;
        right: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid;
      }

      select {
        font-size: 16px;
        text-align: left;
        padding-left: 0;
        padding-right: 0;
        @extend .default;
        max-width: 90%;
        width: 115px;
        direction: ltr;
      }
    }

    .row.dieselpreisrechner {
      border-bottom: 1px white solid;
      margin-bottom: 16px;
    }

    input.dieselpreis {
      width: 80%;
      display: inline;
      height: 28px;
      background-color: $balanced;
      border: 1px solid white;
      margin-left: 5px;
      text-align: center;
      color: white;
      font-weight: bold;
      font-size: 18px;
    }

    .info-small {
      font-size: 12px;

      a {
        color: white;
      }
    }

    hr {
      color: white;
      width: 100%;
    }




    .calc {
      input.hours {
        width: 60px;
        display: inline;
        height: 28px;
        background-color: $balanced;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
        margin-left: 5px;
        text-align: center;
        color: white;
        font-weight: bold;
        font-size: 16px;
        padding-bottom: 6px;
        position: relative;
      }

      input.hours:after {
        color: white;
        right: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid white;
        content: "";
        width: 0;
        height: 0;
        top: 50%

      }
    }
  }

  .indivdualSurchage {
    margin-top: 16px;
  }

  .item-checkbox {
    background-color: transparent;
    color: black;
    border: none;
  }

  .comment {
    font-style: italic;
    font-size: $textSmall;
  }

  .delete {

    text-align: right;

    i {
      font-size: 20px;
    }
  }







}
