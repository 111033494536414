category-title {
  .category-title {

    @extend .default;
    min-height: 65px;

    &.machine {
      font-size: 16px;
      font-weight: normal;
      line-height: 16px;

      .col {

        justify-content: unset;
      }
    }

    font-size: 20px;
    font-weight: bold;
    margin-top: 2px;

    .col {
      display: flex;
      align-items: center;
      justify-content: center;



    }




    .icon {

      font-size: 45px;

    }
  }
}
