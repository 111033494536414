.hint-reminder {

  .popup-container {
    width: 300px;
  }


  .popup-head {
    .popup-title {
      color: rgb(68,68,68);
      font-size: 16px;
    }
  }

  .popup-body {
    .item {


      .item-content {
        span {
          font-size: 14px;
          white-space: normal;
        }
      }
    }
  }
}
