.news-view {

    img {
      max-width: 600px;
    }

   .item {
     background-color: $balanced;

     h2 {
       font-weight: bold;
       @extend .text-subhead;
     }

     p {
       color: white;

     }

   }




 }




