
// Slide up from the bottom, used for modals
// -------------------------------

.slide-in-up {
  @include translate3d(0, 100%, 0);
}
.slide-in-up.ng-enter,
.slide-in-up > .ng-enter {
  @include transition(all cubic-bezier(.1, .7, .1, 1) 400ms);
}
.slide-in-up.ng-enter-active,
.slide-in-up > .ng-enter-active {
  @include translate3d(0, 0, 0);
}

.slide-in-up.ng-leave,
.slide-in-up > .ng-leave {
  @include transition(all ease-in-out 250ms);
}


// Scale Out
// Scale from hero (1 in this case) to zero
// -------------------------------

@-webkit-keyframes scaleOut {
  from { -webkit-transform: scale(1); opacity: 1; }
  to { -webkit-transform: scale(0.8); opacity: 0; }
}
@keyframes scaleOut {
  from { transform: scale(1); opacity: 1; }
  to { transform: scale(0.8); opacity: 0; }
}


// Super Scale In
// Scale from super (1.x) to duper (1 in this case)
// -------------------------------

@-webkit-keyframes superScaleIn {
  from { -webkit-transform: scale(1.2); opacity: 0; }
  to { -webkit-transform: scale(1); opacity: 1 }
}
@keyframes superScaleIn {
  from { transform: scale(1.2); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
}
