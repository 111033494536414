ion-side-menu {

  color: $balanced;


  background: url(../assets/images/logo_mr.png);
  background-size: 250px 133px;
  background-position: top left;
  background-repeat: no-repeat;
  padding-top: 150px;





}

menu-main  {

  ion-content {
    margin-top: 120px!important;
  }


  menu-item {

    .item {
      border: none;
      font-size : 15px;
      padding-top: 6px;
      padding-bottom: 6px;
    }

    .item-icon-left {
      .icon {
        font-size : 20px;
      }
    }
  }


  menu-imprint {

    ion-footer-bar.bar-footer {
      height: 80px;
    }

    .text {
      font-size: 10px;
      padding-left: 20px;
      line-height: 10px
    }
  }

}

ion-side-menu-content {
  box-shadow: rgb(41, 41, 41) -1px 0px 6px 0px !important;


}

