.wrapper {
  .get-cart-as  {

    @extend .default, .rounded-std;
    @include card(1);
    font-size: 16px;
    padding: 5px;
    margin-left: 5px;
    margin-right: 5px;


    button {

      @extend .default;
      text-transform: uppercase;
      font-weight: bold;
      border: none;
      font-size: 16px;

    }


  }

  .message {
    padding: 10px;
    margin-top: 10px;
    width: 100%;
    height: 45px;
    @extend .center-both;
    color: white;
  }

  .success {
    background-color: $balanced;
  }

  .error {
    background-color: $danger;
  }

}
