

my-maschinenring {


  .item.default {
    p {
      color : white;
    }
  }
  a {
    color: white;
  }

  a.call {
    color: black;
    text-decoration: none;
  }
}


