.search-view {

  .search-bar {
      input {
        height: 51px;
        @extend .default;
        font-size: $textNormal;
        width: 100%;
        padding-left: 5%;
      }
    margin-bottom: 1px;
  }

  .search-remember {
    @extend .default, .center-v;
    .icon {
      font-size: $iconSmall;
    }

    .col {
      font-size:$textNormal;
    }

  }


  .result-wrapper {
    padding-top: 10px;
    padding-right: 5px;
    padding-left: 5px;

  }

  .search-result {
    @extend .default;
    font-size:$textNormal;
    margin-bottom: 2px;

    .row {
      @extend .center-v
    }


    .icon {
      font-size: $iconMid;
    }
  }



}
