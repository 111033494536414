ion-header-bar {
  @include card(1);

  .title {
    font-weight: bold !important;
  }

  &.bar {
    border-bottom: none;
  }

}
