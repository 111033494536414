/*
To customize the look and feel of Ionic, you can override the variables
in ionic's _variables.scss file.

For example, you might change some of the default colors:
*/

$light:                           #fff !default;
$stable:                          #f4f0e1 !default;
$positive:                        #4a87ee !default;
$calm:                            #43cee6 !default;
$balanced:                        #56AA1C !default;
$energized:                       #f0b840 !default;
$assertive:                       #EB6200 !default;
$royal:                           #8a6de9 !default;
$dark:                            #444 !default;
$brow:                            #f4f0e1;
$darkbrown:                       #3a1f09;
$error: #eb1b00;
$danger: #EB6200;
$orange: #ef7d00;



//Fontsizes


//Icon

$iconSmall : 30px;
$iconMid : 36px;
$iconLarge: 45px;

//Text

$textSmall : 14px;
$textNormal : 16px;



// The path for our ionicons font files, relative to the built & temporary main.css
$ionicons-font-path: "../assets/fonts" !default;

// Include all of Ionic
@import "../../bower_components/ionic/scss/ionic";
@import "typography";
@import "mixins";
@import "menu";
@import "category-item";
@import "komplettverfahren";
@import "category-list";
@import "category-title";
@import "category-title-without-image";
@import "icons";
@import "news";
@import "ad";
@import "machine-type-list";
@import "machine-calc";
@import "cart-view";
@import "search";
@import "header";
@import "buy-data";
@import "get-cart-as";
@import "callback";
@import "infobar";
@import "rollout";
@import "favorites";
@import "mymaschinering";
@import "signup";
@import "user-management";
@import "faustzahlen-view";
@import "sign-in-view";
@import "chossemr-view";
@import "news-view";
@import "hint-reminder";
@import "dataset_changed";
@import "dataset-selector";
@import "radio-default";
@import "text-view";
@import "web";



$stable:                          #f4f0e1 !default;
$checkbox-on-bg-stable:           #f4f0e1 !default;



//basic

@font-face {
    font-family: 'mr-font';
    font-weight: normal;
    src : url(../assets/fonts/din-reg.woff)

}

@font-face {
  font-family: 'mr-font';
  font-weight: bold;
  src : url(../assets/fonts/din-bold.woff)

}


@font-face {
  font-family: 'roboto';
  src : url(../assets/fonts/Roboto-Regular.ttf)

}

@font-face {
  font-family: 'roboto';
  font-weight: bold;
  src : url(../assets/fonts/Roboto-Bold.ttf)

}

body {
  font-family: "roboto","mr-font", Helvetica, SansSerif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

h1,h2,h3,h4,h5 {
  color : white;
}

.pane {
  background-color: $brow;
}

.default {

  background-color: $balanced;
  color: white;

}

.default-input {
  height: 51px;
  @extend .default;
  font-size: $textNormal;
  width: 100%;
  padding-left: 1%;
  border-radius: 0;
  &::-webkit-input-placeholder {
    color : rgba(255,255,255,0.6)
  }
}

.center-both {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-v {
  display: flex;
  align-items: center;

}


.listwrapper {
  padding: 5px;
}

.rounded-std {
  border-radius: 3px;
}

.default-flat-button {
  @extend .default;
  text-transform: uppercase;
  font-weight: bold;
  border: none;
  font-size: 16px;
}

.default-bordered-button {
  @extend .default;
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid #ffffff;
  padding: 8px;
  font-size: 16px;
}

.container-pad {
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

input {
  color : white;
  height: 5vh !important;
}

select {
  color : white;
  height: 5vh !important;
}

.buy-popup-container {
  input {
    padding: 8px;
  }

  .year {
    margin: 16px 0;
  
    select {
      width: 100%;
      color: black;
    }
  }
  
  .warning {
    color: $error;
  }
}

.upcoming-dataset {
  color: $error;
  padding: 12px;
  text-align: center;
}

.form-errors {
  margin: 5px 0
}
.form-error {
  padding: 24px 0 2px 16px;
  color: $danger;
}

.popup {

  .popup-title {
    color: black;
  }
  color: black;

}


.checkbox-green  {
  @include checkbox-style(white, $balanced, white);

  i {
   color: $balanced!important;
  }
}

