
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $z-index-backdrop;

  width: 100%;
  height: 100%;

  background-color: $loading-backdrop-bg-color;

  visibility: hidden;
  opacity: 0;

  &.visible {
    visibility: visible;
  }
  &.active {
    opacity: 1;
  }

  @include transition($loading-backdrop-fadein-duration opacity linear);
}
