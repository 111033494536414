.news {

  @include card(1);
  color: white;
  height: 60px;
  background-color: $darkbrown;
  border-radius: 3px;

  .col:first-of-type {
    padding: 0;
  }

  img {
    object-fit: cover ;

  }

  .text {

    align-items: center;
    display: flex;

    .wrapper {
      padding-left: 20px;
    }

    .news-head {

    }

    .news-content {
      font-size: 18px;
    }

  }


}