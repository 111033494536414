sign-in-view {

  h4 {
    color : $balanced;

  }

  p {
    color: $balanced
  }

  .pane {
    background-color : white;
  }

  a {
    color: $danger;
  }

  button.other {
    margin-top: 10%;
    &:after {
      content : " ";
      visibility: hidden;
      height: 40px;
      display: block;
      margin-top: 60px;
    }

  }

  img {
    width: 60%;
    max-width: 300px;
    min-width: 280px;
    margin-bottom: 30px;
  }



}













