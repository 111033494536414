ad {
  .ad {
    @extend .default;
    @include card(2);


    img {
     object-fit: cover;
     width: 100%;
      height: auto;
     //width: 50%;
     float: left;


    }

    a {
      position: absolute;
      bottom : -330%;


      right: 8%;
      color: $balanced;
      background-color: white;
      border-radius: 50%;
      width: 65px;
      height: 65px;
      font-size: 45px;
      @extend .center-both

    }

    .text {

      padding-left: 15px;

      .link-container {
        padding-top: 30px;

        position: relative;
      }


      .presented-by {
        font-size: 16px;

      }

      .sponsor {
        margin-top: 10px;
        font-size: 20px;

      }

    }

  }
}


@media (min-width: $grid-responsive-sm-break) and (max-width: $grid-responsive-md-break) {

  ad {


    .ad {

      .text {
        .link-container {
          padding-top: 0px;

        }

      }


      a {
        bottom :-200%;


      }


    }


  }

}




@media (max-width: $grid-responsive-sm-break) {

  ad {





    .ad {

      .text {
        .link-container {
          padding-top: 0px;

        }

      }



      a {
        bottom :-7px;



      }


    }


  }


}

