.add-favorite {


  color: $balanced;
  background-color: white;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  font-size: 45px;
  @extend .center-both

}


.favorite-view {
  @extend .container-pad
}

.favorite-item {
  @extend .default;
  margin-bottom: 1px;

  .remove {
    @extend .center-v;
    text-align: right;
    padding-left: 10%;
  }

}
