@media (min-width:1025px) {
  /* big landscape tablets, laptops, and desktops */

  #appMrBWContainer {

    max-width : 960px;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    -webkit-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.41);
    -moz-box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.41);
    box-shadow: 0px 0px 12px 2px rgba(0,0,0,0.41);

  }

  body {
    background-color: white;
  }


  ad {
    .ad {
      .col {
        @include flex(0, 0, 50%);
        max-width: 50%;
      }
    }
  }





}


.platform-browser {
  ion-content {
    overflow-y : auto!important;
  }
}



[ng-click],
[native-ui-sref]
 {
  cursor: pointer;
}


//Fix for IE 10 / 11 container not centering

@media (min-width:1025px) and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #appMrBWContainer {
    width: 960px;
  }
}
