.buy-data, .no-data {

  @include card(2);
  background-color: white;
  color : $darkbrown;

  // margin-left: 4px;
  // margin-right: 4px;
  margin-top: 8px;

  i {
    font-size: 30px;
  }

  .text {
    padding-top: 30px;
  }

  h2 {
    font-size: 18px;
    font-weight: normal;
    color : $darkbrown;
  }

  h1 {
    font-size: 18px;
    font-weight: bold;
    color : $darkbrown

  }

  p {
    @extend .text-body;
  }

  .button {
    float :right;
    background: none;
    color: $balanced;
    font-weight: bold;
    font-size: $textSmall;

  }

  .button-row {
    width: 100%;
    &:after {
      content: " ";
      clear: both;
      display: block;
    }
  }



}




