.user-management {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 10px;

  h4.line {
    padding-top: 10px;
    border-top: 1px solid white
  }

  .explain {
    font-size:11px;
    font-size:11px;
    font-style: italic;
    margin-bottom: 24px;
  }

  input.text {
    margin-top:10px;
    min-width: 200px;
    width: 60%;
    height: 40px;
    background-color: $balanced;
    border: 1px solid white;
    padding-left: 12px;
    color: white;

    &::placeholder {
      color: lightgrey;
    }
  }

  .success {
    margin-top: 10px;
    text-align: center;
    font-weight: bold;
  }

  .submit {
    margin-top: 10px;
  }

  .item-input {
    input {
      color: white;
    }
  }
}
