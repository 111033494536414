dataset-changed {

  .notification {
    margin-top: 100px;
    @extend .center-both;
    padding: 10px;
    width: 100%;
    min-height: 45px;
    background-color: #EB6200 ;
    color: white;
  }

}
