.machine-calc {

    @include card(2);

    margin-left: 5px;
    margin-bottom: 5px;
    margin-top: 10px;
    margin-right: 5px;
    position: relative;
    font-size: 18px;
    padding: 5px;
    padding-bottom: 80px;

  @extend .default, .rounded-std;


  .item-radio {
    .item-content {

      padding-left: $item-padding *4;
      padding-right: $item-padding;
    }

    .radio-icon {
      left: 0;
      visibility: visible;
      &.ion-checkmark {
        @extend .ion-android-checkbox-outline-blank

      }
    }

    input {
      &:checked + .radio-content .radio-icon {
        /* show the checkmark icon when its checked */
        &.ion-checkmark{
          @extend .ion-android-checkbox-outline
        }
      }

    }

  }

  .select {
    color: white;
    border: none;

    input {
      background-color: $balanced;
    }

    label {
      background-color: $balanced;
    }

    .item-content {
      background-color: $balanced !important;
    }

    &:last-of-type {
      margin-bottom: 10px;
    }

  }

  input.dieselpreis {
    width: 80%;
    display: inline;
    height: 28px;
    background-color: $balanced;
    border: 1px solid white;
    margin-left: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 18px;
  }

  input.hours {
    width: 80%;
    display: inline;
    height: 28px;
    background-color: $balanced;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    margin-left: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 18px;
  }

  input.dieselpreis {
    width: 80%;
    display: inline;
    height: 28px;
    background-color: $balanced;
    border: 1px solid white;
    margin-left: 5px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 18px;
  }


  input.hours:after {
    @extend .icon, .ion-arrow-down-a
  }

  .col {
    @extend .center-v

  }

  .row {
    margin-top: 5px;
  }

  .icon-container {
    position: absolute;
    right: 5%;
    bottom: 20px;
  }

  .addToCart {

    float:left;
    color: $balanced;
    background-color: white;
    border-radius: 50%;
    width: 70px;
    height: 70px;
    font-size: 45px;
    margin-right: 10px;
    @extend .center-both;
  }

  add-favorite {

    float: left;

  }

  .result {
    font-weight: bold;
  }

  .comment {
    font-style: italic;
    font-size: $textSmall;
  }

  .info-small {
      font-size: 12px;
  }

  a, hr {
    color: white;
  }

}
