.faustzahlen-view {


  @extend .container-pad;

  .main-list {
    padding-top: 10px;
    padding-bottom: 10px;

    @include card(1);
    @extend .default;
    margin-bottom: 5px;

    .title {
      padding-left: 10px;
      font-size: 20px;

    }

    .sublist-item, .list-description {
      border-bottom: 1px solid white;

    }

    .sublist-item:last-of-type {
      border-bottom: none;
    }


    .sublist-item {
      margin-top: 0px;
      min-height: 40px;
    }



    .col {
      @extend .center-v;

    }


  }

}

