.category-item .wrapper.komplettverfahren {
    background-color: $orange;

    img {
        padding: 16px;
    }
}

.green {
    color: $balanced;
}


.item-checkbox {
    background-color: transparent;
    color: white;
    border: none;
  }
