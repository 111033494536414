.defaultradio {

  .item {
    border-top: 0;
    border-left:0;
    border-right:0;
    &:first-of-type {
      border-top:none;
    }
  }




  .item-radio input:checked .radio-content .item-content {
      background-color: $balanced !important;
      color: white;

  }

  .item-content {
    @extend .default;
    background-color: $balanced!important;

  }

  i {
    color: white;
  }


}
