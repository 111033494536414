.category-item {

  .wrapper {
    @extend .default;
    border-radius: 3px;
    height: 60px;
    @include card(1);


  }

  .title {
    font-size: 17px;
    line-height: 17px;
    display: flex;
    align-items: center;
  }

  .image-container {

    align-items: center;
    display: flex;
    justify-content: center;
  }

  .icon {

    font-size: 45px;

  }

  &.desktop-only {
    @extend .default;
    border-radius: 3px;
    height: 60px;
    visibility: hidden;
  }



  &.version {
    color: $balanced;
    font-size: 12px;
    span {
      text-decoration: underline;
    }
  }

}



