
.rollout {
  @extend .rounded-std;
  @include card(1);
  margin-bottom: 5px;

  .title {
    font-size: 20px;
    @extend .default;

  }

  .pull-right {
    text-align: right;
    width: 100%;
    padding-right: 5px;

  }

  .icon {
    font-size: 30px;
  }

  .wrapper {
    display: none;

    &.open {
      display: block;
    }

  }

  .content {
    @extend .default;
    margin-top: 1px;
    padding-top: 7px;



  }

}


