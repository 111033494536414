.category-list {

  > * {

    float: left;
    width: 49%;
    margin-top: 5px;

    &:nth-child(2n){
      margin-left: 2%;
    }
  }

  padding-left: 10%;
  padding-right: 10%;
  padding-top: 20px;

  &:last-child {
    &:after {
      content: ".";
      display: block;
      height: 40px;
      width: 100%;
      visibility: hidden;
    }
  }

}


@media (max-width: $grid-responsive-md-break) {

  .category-list {
    padding-left: 5px;
    padding-right: 5px;
  }

}


@media (max-width: $grid-responsive-sm-break) {

  .category-list {

    > * {

      float: left;
      width: 100%;

      &:nth-child(2n){
        margin-left: 0;
      }

    }

  }

}
