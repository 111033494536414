
/**
 * Progress
 * --------------------------------------------------
 */

progress {
  display: block;
  margin: $progress-margin;
  width: $progress-width;
}
