.text-display-4 {
  font-size: 112px;
  font-weight: 300;
  line-height: 128px;
  letter-spacing: -5px;
}

.text-display-3 {
  font-size: 56px;
  font-weight: 400;
  line-height: 64px;
}

.text-display-2 {
  font-size: 45px;
  font-weight: 400;
  line-height: 64px;
}

.text-display-1 {
  font-size: 34px;
  font-weight: 400;
  line-height: 52px;
}

.text-headline {
  font-size: 24px;
  font-weight: 400;
  line-height: 44px;
}

.text-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 44px;
}

.text-subhead {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}

.text-body {
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
}

.text-caption {
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
}

.text-menu {
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
}

.text-button {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  line-height: 32px;
}
