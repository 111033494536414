.text-view {
  @extend .container-pad;

  .text-container {
    padding: 10px;
    @extend .default;

    ul {
      list-style : disc;
      padding-left: 20px ;

    }

  }



}
