.callback {
  @extend .default, .rounded-std;
  @include card(1);
  padding: 10px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  font-size: 16px;


  button {
    @extend .default-flat-button;
  }

  i {
    font-size: 20px;
    color : white;
  }

}
